import web3 from "web3";
import BigNumber from 'bignumber.js'
import { re } from "mathjs";
import { convertToWei } from "../../utils/convertNumber";

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), 'ether')
  if (decimal !== 18)
    amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
  return amount
}

// get total staked by account

export const _isHelaMinted = async (poolContract, account) => {   
  let data = await poolContract.userNftCounts(account);
  return data;
}

export const _buyNodeSale = async (saleNodeContract, itemTier, totalNode, totalPaid, buyId, sig, chainId) => {
  const amount = convertToWei(Number(totalPaid), 18);

  var tierNum = itemTier.replace(/^.*(\d+).*$/i,'$1');

  console.log('amount', amount);
  console.log('itemTier', tierNum);
  console.log('totalNode', totalNode);
  console.log('totalPaid', totalPaid);
  console.log('buyId', buyId);
  console.log('sig', sig);
  console.log('chainId', chainId);

  let args = [tierNum, totalNode, amount, buyId, sig];
  if(Number(chainId) == 56){
    return saleNodeContract.buyNode(...args);
  }else{
    
    return saleNodeContract.buyNode(...args, {value: amount});
  }
  
}


