import React, {useEffect, useState} from "react";
import CrossChain from "../crossChain/crossChain";
import useWindowSize from "../../../hooks/useWindowSize";
import Wallet from "../wallet/wallet";

import 'antd/dist/antd.css';
import { Menu, Dropdown, Button, Row, Col } from 'antd';
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import { CloseOutlined, CodepenOutlined, DeploymentUnitOutlined, DotChartOutlined, DribbbleOutlined, FileSearchOutlined, HomeFilled, MenuOutlined } from '@ant-design/icons';


//Image
import LogoKDG from '../asset/images/logo-img.svg';
import { useActiveWeb3React } from "../../../hooks";
import networkChange from "../../../utils/networkChange";
const { SubMenu } = Menu;

const Header = ({openMenuSp}) => {
    const location = useLocation();
    const { account, chainId } = useActiveWeb3React();
	let defaultMenu;
	
	const [current, setCurrent] = useState(defaultMenu);

	const activeMenu = (e) => {
		if (e.key === 'logo') {
			setCurrent('home');
			return;
		}
		setCurrent(e.key);
	};

    const [isActive, setActive] = useState(false);
	const handleToggle = () => {
		setActive(!isActive);
	};
	const handleHideMenuMobile = () => {
		setActive(false);
	};

    // useEffect(() => {
    //     if (chainId && chainId !== 666888) {
    //         networkChange('0xa2d08');
    //     }
    // }, [chainId]);


    return(
        <div className="c-header">

            <div className="container-header">
                <Menu
                selectedKeys={[current]}
                defaultOpenKeys={['sub1']}
                mode="horizontal"
                onClick={activeMenu}
                className="header-desktop"
            >

                <Menu.Item key="image" className='logo-item-menu'>
                    <Link to="/">
                        <img src="../../../../images/up-swap-logon.svg" alt="logo" className='logo-header' />
                    </Link>

                </Menu.Item>

                <SubMenu key="Earn" title="Earn" icon={<img src="../../../../images/earn.svg"/>}>
                    {/* <Menu.Item key="earn-1">
                        <Link to="/farms">
                            <div className="item-sub-menu">
                                <div className="posi-coming-soon">Coming soon</div>
                                <div className="menu-img">
                                    <img src="../../../../images/farm.svg" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Farms</div>
                                    <div className="menu-desc">Earn double rewards</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="earn-1111">
                        <div className="item-sub-menu">
                            <div className="posi-coming-soon">Coming soon</div>
                            <div className="menu-img">
                                <img src="../../../../images/farm.svg" alt="" />
                            </div>
                            <div className="menu-content">
                                <div className="menu-title">Launchpools</div>
                                <div className="menu-desc">Use your token earn more token</div>
                                <div className="menu-grp-link">
                                    <a href='/stake-hlup'>Stake HLUP</a>
                                    <a href='/stake-token'>Stake Tokens</a>
                                </div>
                            </div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="farm-1">
                        <Link to="/stake-fixible">
                            <div className="item-sub-menu">
                                <div className="posi-coming-soon">Coming soon</div>
                                <div className="menu-img">
                                    <img src="../../../../images/fixed-stake.svg" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Fixed Stake</div>
                                    <div className="menu-desc">Easy stake, High return</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="farm-3">
                        <Link to="/airdrop">
                            <div className="item-sub-menu">
                                <div className="menu-img">
                                    <img src="../../../../images/airdrop.svg" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Airdrop</div>
                                    <div className="menu-desc">UpSwap Airdrop Projects</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="Launchpad" title="Launchpad" icon={<img src="../../../../images/launchpad.svg"/>}>
                    <Menu.Item key="launchpad-1">
                        <Link to="/launchpad/upcoming">
                            <div className="item-sub-menu">
                                <div className="menu-img">
                                    <img src="../../../../images/ido-icon.png" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">IDO</div>
                                    <div className="menu-desc">Invest early in good projects</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="launchpad-2">
                        <Link to="/ino">
                            <div className="item-sub-menu">
                                <div className="posi-coming-soon">Coming soon</div>
                                <div className="menu-img">
                                    <img src="../../../../images/ino-icon.png" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">INO</div>
                                    <div className="menu-desc">Take a look at hottest NFTs launches</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="launchpad-ico">
                        <Link to="/ico">
                            <div className="item-sub-menu">
                                <div className="menu-img">
                                    <img src="../../../../images/ico-icon.png" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">ICO</div>
                                    <div className="menu-desc">Discover hidden gems</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="private-sale">
                        <Link to="/private-sale">
                            <div className="item-sub-menu">
                                <div className="posi-coming-soon">Coming soon</div>
                                <div className="menu-img">
                                    <img src="../../../../images/private-icon.png" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Private Sale</div>
                                    <div className="menu-desc">Participate in early potential token sales</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="NFT-Marketplace" title="NFT Marketplace" icon={<img src="../../../../images/trade.svg"/>}>
                    <Menu.Item key="nft-2">
                        <Link to="/genesis-nft">
                            <div className="item-sub-menu">
                                <div className="menu-img">
                                    <img src="../../../../images/ido-icon.png" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Genesis NFT</div>
                                    <div className="menu-desc">The first piece of UpSwap puzzle</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="nft-1">
                        <Link to="/hela-nft">
                            <div className="item-sub-menu">
                                <div className="menu-img">
                                    <img src="../../../../images/ido-icon.png" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">HeLa NFT</div>
                                    <div className="menu-desc">Passport to HeLa exlusive perks</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>

                </SubMenu>
                
                {process.env.REACT_APP_MODE != "production" && (
                    <Menu.Item key="hela-node" title="HeLa Node" icon={<img src="../../../../images/hela-node.svg"/>}>
                        <Link to="/hela-node">
                            HeLa Node
                        </Link>
                    </Menu.Item>
                )}
                
            
                <SubMenu key="More" title="Services" icon={<img src="../../../../images/service.svg"/>}>
                    <Menu.Item key="sub-trade-3">
                        <Link to="/create-token">
                            <div className="item-sub-menu">
                             
                                <div className="menu-img">
                                    <img src="../../../../images/liquidity.svg" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Create Token</div>
                                    <div className="menu-desc">Create your token now</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="more-1">
                        <Link to="/wallet-generator/details">
                            <div className="item-sub-menu">
                                <div className="posi-coming-soon">Coming soon</div>
                                <div className="menu-img">
                                    <img src="../../../../images/wallet-add.svg" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Wallet Generator</div>
                                    <div className="menu-desc">Generate multiple wallet addresses</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="more-2">
                        <Link to="/multi-sender">
                            <div className="item-sub-menu">
                                {/* <div className="posi-coming-soon">Coming soon</div> */}
                                <div className="menu-img">
                                    <img src="../../../../images/multisender.svg" alt="" />
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">MultiSender</div>
                                    <div className="menu-desc">MultiSender UpSwap Bot</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="claim-hlup">
                        <Link to="/claim-hlup">
                            <div className="item-sub-menu">
                                <div className="menu-img">
                                    <img src="../../../../images/trade.svg" alt=""/>
                                </div>
                                <div className="menu-content">
                                    <div className="menu-title">Claim HLUP</div>
                                    <div className="menu-desc">Claim HLUP testnet</div>
                                </div>
                            </div>
                        </Link>
                    </Menu.Item> */}
                </SubMenu>
                
                <Menu.Item key="connect" className="connect-wallet menu-item-right">
                    <Wallet />
                </Menu.Item>
                {account && (
                    <Menu.Item key="connect-chain" className="connect-wallet menu-item-right">
                        <CrossChain />
                    </Menu.Item>
                )}
                
            </Menu>

            <div>
                <div className={`header-mobile`}>
                    <div className="header-left">
                        <Link to="/">
                            <img src="../../../../images/up-favi.svg" alt="logo" className='logo-mobile' />
                        </Link>

                    </div>
                    <div className='header-center'>
                        <div className='connect-wallet'>
                            <Wallet />
                        </div>
                    </div>
                    <div className="header-right">

                        <Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
                            <MenuOutlined />
                        </Button>
                    </div>
                </div>
                <div className="menu-mobile">
                    <Menu
                        defaultSelectedKeys={['']}
                        defaultOpenKeys={['']}
                        mode="inline"
                        theme="light"
                        className={!isActive ? 'hide-menu menu-mobile-ul' : 'show-menu menu-mobile-ul'}
                    >
                        <Menu.Item key="11" className='item-menu-clo'>
                            <Button type="primary" onClick={handleToggle} className="btn-menu-mobile-close">
                                <CloseOutlined />
                            </Button>
                        </Menu.Item>

                        <SubMenu key="earn-mobile" icon={<img src="../../../images/earn.svg"/>} title="Earn">
                            {/* <Menu.Item key="earn-mobile-1" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/farms">
                                    Farms
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="hup-mobile-1" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/stake-hlup">
                                    Stake HLUP
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="token-mobile-1" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/stake-token">
                                    Stake Tokens
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="fixible-mobile-1" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/stake-fixible">
                                    Fixed Stake
                                </Link>
                            </Menu.Item> */}
                            <Menu.Item key="airdrop-moble-3" onClick={handleHideMenuMobile}>
                                <Link to="/airdrop">
                                    Airdrop
                                </Link>
                            </Menu.Item>
                        </SubMenu>

                        
                        <SubMenu key="launchpad-mobile" icon={<img src="../../../images/launchpad.svg"/>} title="Launchpad">
                            <Menu.Item key="launchpad-mobile-1" onClick={handleHideMenuMobile}>
                                <Link to="/launchpad/upcoming">
                                    IDO
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="launchpad-mobile-2" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/ino">
                                    INO
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="ico-mobile-2" onClick={handleHideMenuMobile}>
                                <Link to="/ico">
                                    ICO
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* <Menu.Item key="NFT-Marketplace" onClick={handleHideMenuMobile} icon={<img src="../../../../images/trade.svg"/>}>
                            <Link to="/genesis-nft">
                                NFT Marketplace
                            </Link>
                        </Menu.Item> */}

                        <SubMenu key="NFT-Marketplace-mobile" title="NFT Marketplace" icon={<img src="../../../../images/trade.svg"/>}>
                            <Menu.Item key="nft-2-mobile" onClick={handleHideMenuMobile}>
                                <Link to="/genesis-nft">
                                    Genesis NFT
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="nft-1-mobile" onClick={handleHideMenuMobile}>
                                <Link to="/hela-nft">
                                    HeLa NFT
                                </Link>
                            </Menu.Item>

                        </SubMenu>
                        
                        {/* <Menu.Item key="hela-nft-mobile" onClick={handleHideMenuMobile} title="Genesis NFT" icon={<img src="../../../../images/trade.svg"/>}>
                            <Link to="/hela-nft">
                                HeLa NFT
                            </Link>
                        </Menu.Item> */}
                        {process.env.REACT_APP_MODE != "production" && (
                            <>
                                <Menu.Item key="hela-node-mobile" onClick={handleHideMenuMobile} title="HeLa Node" icon={<img src="../../../../images/hela-node.svg"/>}>
                                    <Link to="/hela-node">
                                        HeLa Node
                                    </Link>
                                </Menu.Item>
                            </>
                        )}
                        
                        <SubMenu key="more-mobile" title="More">
                            {/* <Menu.Item key="more-mobile-1" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/wallet-generator/details">
                                    Wallet Generator
                                </Link>
                            </Menu.Item> */}
                            
                            <Menu.Item key="more-private-salemobile" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/private-sale">
                                    Private sale
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="create-token-mobile" onClick={handleHideMenuMobile}>
                                <Link to="/create-token">
                                    Create token
                                </Link>
                            </Menu.Item>
                         
                            <Menu.Item key="more-multi-sender" onClick={handleHideMenuMobile}>
                                <div className="posi-coming-soon">Coming soon</div>
                                <Link to="/multi-sender">
                                    MultiSender
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* <Menu.Item key="claim-hlup-mo" onClick={handleHideMenuMobile}>
                            <Link to="/claim-hlup">
                                Claim HLUP
                            </Link>
                        </Menu.Item> */}
                    </Menu>
                    
                </div>
            </div>
            </div>

                
            
        </div>
    );
};

export default Header;
