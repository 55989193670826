
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Input, Select, Table, Progress, Alert, Empty, Tag } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import tokenInfoAbi from '../../config/abi/standardTokenAbi.json';
import nodeSaleAbi from '../../config/abi/nodeSaleAbi.json';
import web3 from 'web3';
import { ethers } from 'ethers';
import { getInformationByChain } from '../../config/network/multichainAddresses';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';
import { _buyNodeSale, _isHelaMinted } from './utils';
import { currenciesArray } from '../../config/network/config';
import networkChangeByNumber from '../../utils/networkChangeByNumber';
import { HelaNodeApi } from '../../config/api/helaNodeApi';
import { getBalance } from '../utils';
import { convertFromWei } from '../../utils/convertNumber';
import { useSaleNodeContract } from '../../hooks/useContract';
import { SALENODE_SC_BY_CHAINID } from '../../constants';
import CountdownReloadComponent from '../../utils/countDownReload';


const NodeItem = (props) => {
    const {nodeActive, helaNode} = props;
    const { account, chainId } = useActiveWeb3React();

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
   
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [ethBalance, setEthBalance] = useState(0);
    const [saleNodeSc, setSaleNodeSc] = useState('');
    const [limitPerWallet, setLimitPerWallet] = useState(10);

    const [param, setParam] = useState(
        {
            page: 0,
            size: 10,
            sort: 'id,asc',
            ownerAddress: account
        }
    );
    const [totalNodeBuyed, setTotalNodeBuyed] = useState(0);
    const [isModalNode, setIsModalNode] = useState(false);
    const [mintQuantity, setMintQuantity] = useState(1);
    const [totalPay , seTotalPay] = useState(0);
    const [myNodes, setMyNodes] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState(limitPerWallet-totalNodeBuyed);
   
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let saleNodeAddress = saleNodeSc ? saleNodeSc : SALENODE_SC_BY_CHAINID[chainId];

    let saleNodeContract = useSaleNodeContract(saleNodeAddress);

    let ethBscAddress = '0x2170ed0880ac9a755fd29b2688956bd959f933f8';


    const getEthBalance = async ()=>{
        if(account){
            if(chainId == 56){
                let balance_  = await getBalance(tokenInfoAbi, ethBscAddress, account);
                let balance = convertFromWei(balance_,Number(18));
                setEthBalance(balance);
            }else{
                
                    w3.eth.getBalance(account, (err, balance) => {
                        if (balance) {
                            let _balance = web3.utils.fromWei(
                                web3.utils.toBN(balance),
                                "ether"
                            );
                        
                            setEthBalance(_balance);
                        }
                    });
                
            }
        }
    }

    const getSCSettings = async (keyAddress, key)=>{
        try {
            let res = await HelaNodeApi.getSettingKey(keyAddress);
          
            if (res.status === 200 && res.data && res.data?.settingValue) {
                if(key == 'sc'){
                    setSaleNodeSc(res.data?.settingValue);
                }
                if(key == 'limit'){
                    setLimitPerWallet(Number(res.data?.settingValue));
                }
                
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }
    useEffect(() => {
        if(account){
            getSCSettings('NODE_SALE_CONTRACT', 'sc');
            getSCSettings('NODE_SALE_MAX_PER_WALLET', 'limit');
        }
    }, [account, limitPerWallet, saleNodeSc]);

    const getMyNodes = async ()=>{
        setLoadingData(true);
        try {
            
            let res = await HelaNodeApi.getMyNodes(account, param);
            
            if (res.status === 200 && res.data) {
                setMyNodes(res.data);
            } else {
                setMyNodes([]);
            }
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            console.log('Error: ', error);
            setMyNodes([]);
        }
    }

    const getAmountTierByAccount = async() => {
        setMintQuantity(1);
        try {
            const response = await HelaNodeApi.getAmountByNodes(account, nodeActive?.itemTier);
          
            if(response.status == 200 && response.data && response.data?.totalNode){
                let totalBuyed = response.data?.totalNode ? response.data?.totalNode : 0;
                setTotalNodeBuyed(totalBuyed);
                setAvailableQuantity(totalBuyed > limitPerWallet ? 0 : limitPerWallet-totalBuyed);
            }else{
                setTotalNodeBuyed(0);
                setAvailableQuantity(limitPerWallet);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (nodeActive) {
            seTotalPay(nodeActive?.itemPrice * mintQuantity)
        }
    }, [nodeActive])

    useEffect(() => {
        if(account){
            getMyNodes();
        }
    }, [account, param]);

    useEffect(() => {
        if(account && nodeActive){
            getAmountTierByAccount();
        }
    }, [account, nodeActive]);

    useEffect(() => {
        if(account){
            getEthBalance();
        }
    }, [account]);

    
    const columns = [
        {
            title: <><span className='text-darkgray text-weight-500'>Tier</span></>,
            dataIndex: 'itemTier',
            key: 'tieitemTier',
            render: (text, record) => {
				
				return (
					<span className='text-regular-blue font-18 text-weight-600'>
						{record?.itemTier}
					</span>
				)
			}
        },
        {
            title: <><span className='text-darkgray text-weight-500'>Quantity</span></>,
            dataIndex: 'quantity',
            key: 'quantity',
			render: (text, record) => {
				
				return (
					<span className='text-regular-blue font-18 text-weight-600'>
						{record?.quantity ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.quantity) : 0}
					</span>
				)
			}
        },
        {
            title: <><span className='text-darkgray text-weight-500'>Total Price</span></>,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
			render: (text, record) => {
				
				return (
					<span className='text-regular-blue text-weight-600'>
						{record?.totalPrice ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.totalPrice) : 0}
					</span>
				)
			}
        },
        {
            title: <><span className='text-darkgray text-weight-500'>Status</span></>,
            dataIndex: 'orderEnum',
            key: 'orderEnum',
			render: (text, record) => {
				let color = 'red';
                if(record?.orderEnum == 'FINISHED'){
                    color = 'green';
                }
				return (
					<Tag color={color}>{record?.orderEnum}</Tag>
				)
			}
        },
    ];

    const handleIncreaseQuantity = () => {
        let quantity = mintQuantity + 1;

        let canMaxBuy = nodeActive?.itemQty - nodeActive?.itemSoldQty;

        if(canMaxBuy > quantity){
            if(Number(quantity) > availableQuantity){
                setMintQuantity(availableQuantity);
                seTotalPay(nodeActive?.itemPrice * availableQuantity);
            }else{
                setMintQuantity(quantity);
                seTotalPay(nodeActive?.itemPrice * quantity);
            }
        }else{
            setMintQuantity(Number(canMaxBuy));
            seTotalPay(nodeActive?.itemPrice * Number(canMaxBuy));
        }

    //    setMintQuantity(quantity);
    //    seTotalPay(nodeActive?.itemPrice * quantity);
    };
    const handleDecreaseQuantity = () => {
        let quantity = mintQuantity - 1;
       setMintQuantity(quantity);
       seTotalPay(nodeActive?.itemPrice * quantity);
    };

    const handleChangeAmount = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            // setMintQuantity(inputValue);
            // seTotalPay(nodeActive?.itemPrice * inputValue);

            let canMaxBuy = nodeActive?.itemQty - nodeActive?.itemSoldQty;

            if(canMaxBuy > availableQuantity){
                if(inputValue > availableQuantity){
                    setMintQuantity(Number(availableQuantity));
                    seTotalPay(nodeActive?.itemPrice * Number(availableQuantity));
                }else{
                    setMintQuantity(Number(inputValue));
                    seTotalPay(nodeActive?.itemPrice * Number(inputValue));
                }
            }else{
                setMintQuantity(Number(canMaxBuy));
                seTotalPay(nodeActive?.itemPrice * Number(canMaxBuy));
            }
        }
    };

    const onBuyNode = async () => {
        setLoading(true);
        if(ethBalance < totalPay){
            setLoading(false);
            Store.addNotification(addNotify('Total pay invalid.', 'danger'));
            return;
        }
        if(saleNodeAddress == ''){
            setLoading(false);
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }

        try {
            let data = {
                "buyerWallet": account,
                "chainId": chainId.toString(),
                "itemTier": nodeActive?.itemTier,
                "paymentToken": "ETH",
                "quantity": mintQuantity,
                "unitPrice": nodeActive?.itemPrice
            }

            let rsSign = await HelaNodeApi.genSignBuyNode(data);


            if(rsSign && rsSign.status == 200 && rsSign?.data?.orderId){

                let sign = rsSign?.data?.sign;
                let orderId = rsSign?.data?.orderId;
                
                // let sign = "0xf9ee70164931ea7325a68f835a5633e5c871c6846dda0678ca469ddf17b2d8555708ac7a70feadb751a7836d04214a7158b0bf89c096415e775b62ab1157fd1e1c";
                // let orderId = 12;
                
                await _buyNodeSale(saleNodeContract , nodeActive?.itemTier, mintQuantity, Number(totalPay), orderId, sign, chainId)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then(async (resTransaction) => {

                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            
                            let payload = {
                                "buyHash": res.hash,
                                "buyId": orderId,
                                "chainId": chainId.toString(),
                                "user": account
                            };
                            let resAp = await HelaNodeApi.updateOrderHash(payload, orderId);
                            if (resAp.status === 200) {
                                Store.addNotification(addNotify('Buy successfully', 'success'));
                                setLoading(false);
                                // setShowBuyModal(false);

                                setTimeout(() => {
                                
                                    window.location.reload();
                                }, 2000);
                            }else{
                                Store.addNotification(addNotify('Server Error', 'danger'));
                                setLoading(false);
                            }
                            
                        }
                        else {
                            setLoading(false);
                            Store.addNotification(addNotify('Transaction failed', 'warning'));
                            return;
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        Store.addNotification(addNotify(error?.message ? error?.message : 'Server Error !!!', 'danger'));
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.log('error sc', error);
                    if (error) {
                        if (error.code === 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }
                });
            }else{
                setLoading(false);
                Store.addNotification(addNotify('Sign not found.', 'warning'));
                return;
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
            Store.addNotification(addNotify(error.message ? error.message : 'Over 10 node per wallet', 'warning'));
            return;
        }

        
        
    };

    const handleOk = () => {
        setIsModalNode(false);
        // window.location.reload();
    };

    const handleCancel = () => {
        setIsModalNode(false);
    };
    const showYourNode = () => {
        if(account){
            getMyNodes();
        }
        setIsModalNode(true);
    };

    let percentProcess = nodeActive?.itemSoldQty/nodeActive?.itemQty * 100;
    let nowDate = new Date();
    let startTime = helaNode?.startTime ? new Date(helaNode?.startTime) : '';
    let endTime = helaNode?.endTime ? new Date(helaNode?.endTime) : '';


    return (
        <>
            <Progress
                strokeColor={{
                    '0%': '#19B4CD',
                    '100%': '#5EE7D5'
                }}
                percent={percentProcess}
                showInfo={false}
                className="node-progress"
            />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className='gutter-row' xs={12}>
                    <span className='text-regular-blue'>{new Intl.NumberFormat("ja-JP").format(nodeActive?.itemSoldQty)} <small>Nodes</small></span>
                </Col>
                <Col className='gutter-row text-right'xs={12}>
                    <span className='text-regular-blue'>{new Intl.NumberFormat("ja-JP").format(nodeActive?.itemQty)} <small>Nodes</small></span>
                </Col>
            </Row>
            <div className='form-border mt-10'>
                {startTime != '' && startTime < nowDate && endTime > nowDate && (
                    <div className="flex-btw">
                        <span className="text-regular-darkgray">End In</span>
                        <span className="text-white weight-400">{CountdownReloadComponent(helaNode?.endTime)}</span>
                    </div>
                )}
               
                <Row gutter={20} className="mt-10">
                    <Col xs={24} sm={12}>
                        <label className='text-regular-darkgray'>Price per Node</label>
                        <div className="text-weight-500 mt-10">
                            <span className='text-regular-blue text-weight-600'>{nodeActive?.itemPrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(nodeActive?.itemPrice) : 0}</span> ETH
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <label className='text-regular-darkgray'>Quantity</label>
                        <Input
                            onChange={handleChangeAmount}
                            className='grp-input'
                            style={{ width: '100%', marginTop: '10px' }}
                            addonBefore={
                                <button onClick={handleDecreaseQuantity} disabled={mintQuantity <= 1 || Number(nodeActive?.itemSoldQty) >= Number(nodeActive?.itemQty)} className='btn-increase'>-</button>
                            } 
                            addonAfter={
                                <button onClick={handleIncreaseQuantity} disabled={Number(mintQuantity) >= Number(availableQuantity) || Number(nodeActive?.itemSoldQty) >= Number(nodeActive?.itemQty)} className='btn-increase'>+</button>
                            }
                            readOnly={Number(nodeActive?.itemSoldQty) >= Number(nodeActive?.itemQty) || availableQuantity == 0}
                            value={mintQuantity} 
                        />
                    </Col>
                </Row>
            
                <div className='flex-btw mt-10'>
                    <span className='text-regular-darkgray'>Your ETH Balance</span>
                    <div className=''>
                        <span className='text-weight-600 text-regular-blue font-18 mr-5'>{ethBalance > 0 ? new Intl.NumberFormat("ja-JP", {
                            maximumFractionDigits: 5,
                            }).format(ethBalance) : 0}
                        </span>
                        <small className='text-gray text-weight-500'>ETH</small>
                    </div>
                </div>
                <div className='flex-btw mt-10'>
                    <div className='text-regular-darkgray'>Total Payment</div>
                    <div className='text-regular-blue'>
                        <span className='text-weight-600 font-18 mr-5'>{totalPay ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(totalPay) : 0}</span>
                        <small className='text-gray text-weight-500'>ETH</small>
                    </div>
                </div>
                <div className='flex-btw mt-10'>
                    <div className='text-regular-darkgray'>Limit per Wallet</div>
                    <div className='text-regular-blue'>
                        <span className='text-weight-600 font-18 mr-5'>{limitPerWallet}</span>
                        <small className='text-gray text-weight-500'>Nodes</small>
                    </div>
                </div>
                {totalNodeBuyed > 0 && (
                    <div className='flex-btw mt-10'>
                        <div className='text-regular-darkgray'>Your Purchased</div>
                        <div className=''>
                            <span className='text-weight-600 font-18 mr-5 text-regular-blue'>{totalNodeBuyed}</span>
                            <small className='text-gray text-weight-500'>{totalNodeBuyed > 1 ? 'Nodes' : 'Node'}</small>
                        </div>
                    </div>
                )}
                
                

                {chainId && chainId == 42161 ? (
                    <>
                        {nodeActive ? (
                            <>
                                {startTime != '' && startTime < nowDate && endTime < nowDate && (
                                    <Alert className='mt-10' description={<div className='text-weight-600 text-center'>ENDED</div>} type="warning" />
                                )}
                                {startTime != '' && endTime != '' && startTime > nowDate && endTime > nowDate && (
                                    <>
                                        <div className="text-weight-600 text-center">Start In</div>
                                        <div className="text-weight-600">
                                            {helaNode?.startTime ? CountdownReloadComponent(helaNode?.startTime) : ''}
                                        </div>
                                    </>
                                )}

                                {startTime != ''&& endTime != ''  && startTime < nowDate && endTime > nowDate && (
                                    <>
                                        {nodeActive?.itemQty != nodeActive?.itemSoldQty ? (
                                            <>
                                                <button onClick={onBuyNode} className='btn-basic mt-10' disabled={!account || ethBalance < totalPay || loading || availableQuantity == 0}>
                                                    Buy 
                                                </button>
                                            </>
                                        ):(
                                            <>
                                                <div className='mt-15'>
                                                    <Alert description={<span className='text-weight-500'>SOLD OUT</span>} type="success" />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}


                                
                            </>
                        ):(
                            <>
                                <button className='btn-basic' disabled>
                                    Node Not Found
                                </button>
                            </>
                        )}
                        
                    </>
                ):(
                    <>
                        {chainId ? (
                            <>
                                <div className='text-center'>
                                   
                                    <button className="btn-basic w-fitcontent bg-danger mt-15" onClick={()=> networkChangeByNumber(42161)}>
                                        Incorrect network. Switch to Arbitrum One
                                    </button>
                                </div>
                            </>
                        ):(
                            <>
                                <Alert description={<span className='text-weight-500 font-18 mt-10'>Please connect wallet</span>} type="error" />
                            </>
                        )}
                        
                    </>
                )}
            </div>
            <div className='form-border mt-10'>
                <div className='flex-btw'>
                    <div className='text-regular-darkgray'>Your Nodes</div>
                    <button className="btn-basic w-fitcontent p-5 pt-3 height-auto" onClick={showYourNode}>Detail</button>
                </div>
            </div>
            
            <Modal className="modal-mint" open={isModalNode} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal-content-mint">
                    <div className="title text-red">
                        Your Nodes
                    </div>
                    <div className="desc">
                        {myNodes && myNodes.length > 0 ? (
                            <>
                                <Table pagination={false}  scroll={{y: 300 }} dataSource={myNodes} columns={columns} />
                            </>
                        ):(
                            <>
                                <Empty description="No data" />
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            {loading && (
                <>
                    <Loader />
                </>
            )}
        </>
    )
}

export default NodeItem
