import React, {useState} from "react";
import useWindowSize from '../../../hooks/useWindowSize';

//Images
import netLogoBSC from '../asset/images/binance.png';
import netLogoPOLY from '../asset/images/polygon.png';
import netLogoETH from '../asset/images/eth.png';
import netLogoARB from '../asset/images/arb.png';
import netLogoHELA from '../asset/images/helalogo.svg';
import undifineLogo from '../asset/images/war.png';
import { useActiveWeb3React } from "../../../hooks";
import networkChange from "../../../utils/networkChange";
import { CHAINID_FULLNAME, MAPPING_CHAINID } from "../../../constants";

const CrossChain = () => {
    const [isShow, setIsShow] = useState(false);
    const { chainId } = useActiveWeb3React();
    const size = useWindowSize();
    const sizeTablet = 1024;
     
    let netName = '';
    let netLogo = '';

    switch (chainId) {
        case 56:
            netName = CHAINID_FULLNAME.bsc;
            netLogo = netLogoBSC;
            break
        case 97:
            netName = CHAINID_FULLNAME.bsctestnet;
            netLogo = netLogoBSC;
            break
        case 137:
            netName = CHAINID_FULLNAME.poly;
            netLogo = netLogoPOLY;
            break
        case 1:
            netName = CHAINID_FULLNAME.eth;
            netLogo = netLogoETH;
            break
        case 42161:
            netName = CHAINID_FULLNAME.arb;
            netLogo = netLogoARB;
            break
        case 421613:
            netName = CHAINID_FULLNAME.arbtestnet;
            netLogo = netLogoARB;
            break
        case 8668:
            netName = CHAINID_FULLNAME.hela;
            netLogo = netLogoHELA;
            break
        default:
            netName = CHAINID_FULLNAME.undefined;
            netLogo = undifineLogo;
    }

    const handleCrossChainSP = () => {
        if(size.width <= sizeTablet) {
            setIsShow(!isShow);
        } else {
            setIsShow(false);
        }
    }
    
    return(
        <div className="c-crossChain">
            <div className="c-crossChain__showName" onClick={() => handleCrossChainSP()}><img src={netLogo} alt={netName} />{netName}</div>
            <ul className={`c-crossChain__list${isShow ? ' is-showChain' : ''}`}>
                <li onClick={() => networkChange(MAPPING_CHAINID.hela)}><img src={netLogoHELA} alt="" />HeLa</li>
                {/* <li onClick={() => networkChange(MAPPING_CHAINID.helatestnet)}><img src={netLogoHELA} alt="" />HeLa Testnet</li> */}
                {/* <li onClick={() => networkChange(MAPPING_CHAINID.bsc)}><img src={netLogoBSC} alt=""/>BSC</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.poly)}><img src={netLogoPOLY} alt=""/>Polygon</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.eth)}><img src={netLogoETH} alt="" />Ethereum</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.arb)}><img src={netLogoARB} alt="" />Arbitrum</li> */}

                {/* <li className="chain-disable"><img src={netLogoHELA} alt="" />HeLa</li> */}
                <li onClick={() => networkChange(MAPPING_CHAINID.bsc)}><img src={netLogoBSC} alt=""/>BSC</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.arb)}><img src={netLogoARB} alt="" />Arbitrum</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.poly)}><img src={netLogoPOLY} alt=""/>Polygon</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.eth)}><img src={netLogoETH} alt="" />Ethereum</li>
                <li onClick={() => networkChange(MAPPING_CHAINID.arb)}><img src={netLogoARB} alt="" />Arbitrum</li>
            </ul>
        </div>
    );
}

export default CrossChain;