
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Modal, Pagination, Input, Select, Table, InputNumber, Alert, Tag, Spin, Empty, Progress } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';

import web3 from 'web3';
import { getInformationByChain } from '../../config/network/multichainAddresses';

import { Loader } from '../common/component';
import FooterHome from '../common/footer/footerHome';
import { _isHelaMinted } from './utils';

import { HelaNodeApi } from '../../config/api/helaNodeApi';
import NodeItem from './NodeItem';
import { chain } from 'mathjs';
import networkChangeByNumber from '../../utils/networkChangeByNumber';

const { Option } = Select;

const HelaNode = () => {
    const { account, chainId } = useActiveWeb3React();

    const [param, setParam] = useState(
        {
            page: 0,
            size: 10,
            sort: 'id,asc',
        }
    );
    const nodeDetailSection = useRef(null);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

   
    const [loadingMint, setLoadingMint] = useState(false);
    const [viewDisplay , setViewDisplay] = useState('grid');
    const [helaNodes, setHelaNodes] = useState([]);
    const [helaNodeItem, setHelaNodeItem] = useState([]);
    const [nodeActive, setNodeActive] = useState(null);
    const [totalRow, setTotalRow] = useState(0);
    const [isModalSuccess, setIsModalSuccess] = useState(false);
    const [isModalFailed, setIsModalFailed] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
   

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);


    const handleOk = () => {
        // setIsModalSuccess(false);
        // setIsModalFailed(false);
        // setMintItem(null);
        // window.location.reload();
    };

    const handleCancel = () => {
        // setIsModalSuccess(false);
        // setIsModalFailed(false);
        // setMintItem(null);
        // window.location.reload();
    };

    const getHelaNodes = async ()=>{
        setLoadingData(true);
        try {
            let para = {
                page: 0,
                size: 20,
                sort: 'id,desc',
            }
            let res = await HelaNodeApi.getHelaNodes(para);
            
            if (res.status === 200 && res.data) {
                setHelaNodes(res.data);
                
            } else {
                setHelaNodes([]);
            }
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            console.log('Error: ', error);
            setHelaNodes([]);
        }
    }

    const getHelaNodeItems = async ()=>{
        setLoadingData(true);
        try {
            
            let res = await HelaNodeApi.getHelaNodeItems(param);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200 && res.data) {
                setHelaNodeItem(res.data);
                
            } else {
                setHelaNodeItem([]);
            }
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            console.log('Error: ', error);
            setHelaNodeItem([]);
        }
    }

    useEffect(() => {
        getHelaNodes();
        getHelaNodeItems();
    }, [account]);

    useEffect(() => {
        if(helaNodeItem && nodeActive == null){
            let keyNode = localStorage.getItem('keyItem') ? localStorage.getItem('keyItem') : 0;
            setNodeActive(helaNodeItem[keyNode]);
         
        }
    }, [helaNodeItem]);

    const handleActiveNode = (item, key) => {
        setNodeActive(item);
        localStorage.setItem('keyItem', key);
        window.scrollTo({ top: nodeDetailSection.current.offsetTop, behavior: 'smooth', });
    };

    // const columns = [
    //     {
    //         title: <><div className='text-darkgray text-weight-500 text-center'>#</div></>,
    //         dataIndex: 'no',
    //         key: 'no',
	// 		render: (text, record, index) => {
				
	// 			return (
	// 				<div className='text-center text-gray'>
	// 					{index + 1}
	// 				</div>
	// 			)
	// 		}
    //     },
    //     {
    //         title: <><span className='text-darkgray text-weight-500'>Tier</span></>,
    //         dataIndex: 'itemName',
    //         key: 'itemName',
	// 		render: (text, record, index) => {
				
	// 			return (
	// 				<span className={`text-regular-blue ${ nodeActive && nodeActive.id ==  record?.id ? 'font-20 text-info': 'text-gray'}`}>
	// 					{record?.itemName}
	// 				</span>
	// 			)
	// 		}
    //     },
    //     {
    //         title: <><span className='text-darkgray text-weight-500'>Price <small>(ETH)</small></span></>,
    //         dataIndex: 'itemPrice',
    //         key: 'itemPrice',
	// 		render: (text, record) => {
				
	// 			return (
	// 				<span className={`text-regular-blue ${ nodeActive && nodeActive.id ==  record?.id ? 'font-20 text-info': 'text-gray'}`}>
	// 					{record?.itemPrice ? new Intl.NumberFormat("ja-JP").format(record?.itemPrice) : 0}
	// 				</span>
	// 			)
	// 		}
    //     },
    //     {
    //         title: <><span className='text-darkgray text-weight-500'>Total</span></>,
    //         dataIndex: 'itemQty',
    //         key: 'itemQty',
	// 		render: (text, record) => {
				
	// 			return (
	// 				<span className={`text-regular-blue ${ nodeActive && nodeActive.id ==  record?.id ? 'font-20 text-info': 'text-gray'}`}>
	// 					{record?.itemQty ? new Intl.NumberFormat("ja-JP").format(record?.itemQty) : 0}
	// 				</span>
	// 			)
	// 		}
    //     },
    //     {
    //         title: <><span className='text-darkgray text-weight-500'>Sold</span></>,
    //         dataIndex: 'itemSoldQty',
    //         key: 'soitemSoldQtyld',
	// 		render: (text, record) => {
	// 			let sold = 0;
	// 			return (
	// 				<span className={`text-regular-blue ${ nodeActive && nodeActive.id ==  record?.id ? 'font-20 text-info': 'text-gray'}`}>
	// 					{new Intl.NumberFormat("ja-JP").format(record?.itemSoldQty)}
	// 				</span>
	// 			)
	// 		}
    //     }
    // ];


    const onChange_Pagi = (pageNumber) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const changeView = (view) => {
        setViewDisplay(view);
    };

    return (
        <>
            <div className='helanode-page'>
                <div className="container">
                    <Row gutter={20}>
                        <Col xs={24} sm={14}>
                            <div className='helanode-info'>
                                <div className='node-banner'>
                                    <img src='../images/hela-node-banner.png' />
                                </div>
                                <div className='node-title text-weight-500 text-regular-blue'>
                                    {helaNodes[0]?.title}
                                </div>
                                <div className='node-description'>
                                    {helaNodes[0]?.description}
                                </div>

                              
                                <div className="social flex-center">
                                    <a href="https://discord.com/invite/helalabs" target="_blank"><img src="../images/dis.svg" /></a>
                                    <a href="https://x.com/HeLa_Labs" target="_blank"><img src="../images/x.svg" /></a>
                                    <a href="https://t.me/hela_official" target="_blank"><img src="../images/tele.svg" /></a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={10}>
                            <div className='helanode-info-detail' ref={nodeDetailSection}>
                                <div className='node-info-title'>
                                    {nodeActive ? nodeActive?.itemName : ''}
                                </div>
                                {/* <div className='node-info-description'>
                                    {nodeActive ? nodeActive?.itemDescription : ''}
                                </div> */}
                                <div className='node-form'>
                                    {loadingData ? (
                                        <>
                                            <div className='text-center'><Spin size='large' /></div>
                                        </>
                                    ):(
                                        <>
                                            {chainId ? (
                                                <>
                                                    {chainId == 42161 ? (
                                                        <>
                                                            <NodeItem helaNode={helaNodes[0]} nodeActive={nodeActive}/>
                                                        </>
                                                    ):(
                                                        <>
                                                            <button className='btn-basic bg-danger mt-15' onClick={()=> networkChangeByNumber(42161)}>Switch network to Arbitrum</button>
                                                        </>
                                                    )}
                                                </>
                                            ):(
                                                <>
                                                    <Alert description="Please connect wallet" type='error'/>   
                                                </>
                                            )}
                                            
                                        </>
                                    )}
                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                   <div className='node-tier-list'>
                        {loadingData ? (
                            <>
                                <div className='text-center'><Spin size='large' /></div>
                            </>
                        ):(
                            <>
                                <div className="nodes">
        
                                    <div className="nodes-list">
                                        <div className="border-line d-flex-left mb-15 w-fitcontent">
                                            <button onClick={()=>changeView("grid")} className={`btn-grid ${viewDisplay == "grid" ? 'btn-grid-active':''}`}><AppstoreOutlined /></button>
                                            <button onClick={()=>changeView("list")} className={`btn-grid ${viewDisplay == "list" ? 'btn-grid-active':''}`}><UnorderedListOutlined /></button>
                                            
                                        </div>
                                        {viewDisplay == "grid" && (
                                            <Row gutter={30}>
                                                {helaNodeItem.length > 0 ? helaNodeItem.map((item, key) => {

                                                    let percentSold = Number(item?.itemSoldQty/item?.itemQty * 100);

                                                    return(
                                                        <Col xs={24} sm={6} key={`key-${key}`}>
                                                            <div key={`keyitem-${key}`} className={`nodes-item ${item?.id == nodeActive?.id ? 'border-active':''} ${item?.itemSoldQty == item?.itemQty ? 'border-soldout':''}`} onClick={()=> handleActiveNode(item, key)}>
                                                                <div className="node-top">
                                                                    <div className="node-title flex-btw">
                                                                        <div className="flex-left text-black-green text-weight-400 gap-5">
                                                                            <img src="../images/node-item.svg" />
                                                                            {item?.itemName}
                                                                        </div>
                                                                        <img src="../images/arb.svg" />
                                                                        
                                                                    </div>
                                                                    <div className="nodes-info">
                                                                        <div className="flex-btw text-white">
                                                                            <span className="text-black-green">Price per Node</span>
                                                                            <span className="text-weight-500">
                                                                                {item?.itemPrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(item?.itemPrice) : 0} ETH
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-btw text-white mt-15">
                                                                            <span className="text-black-green">Node for Sale</span>
                                                                            <div className="d-flex-center gap-5">
                                                                                <span className="text-weight-500">{item?.itemQty > 0 ? new Intl.NumberFormat("ja-JP").format(item?.itemQty) : 0} Nodes</span> 
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-btw text-white mt-15">
                                                                            <span className="text-black-green">Pay in</span>
                                                                            <div className="text-weight-500 flex-btw">
                                                                                <img className="w-25" style={{marginRight: '5px'}} src="../images/eth.svg" alt="" />
                                                                                <span>ETH</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="node-progress">
                                                                        <Progress showInfo={false} percent={percentSold} />
                                                                        <div className="flex-btw text-white">
                                                                            <span className="text-weight-500 text-black-green">{item?.itemSoldQty ? item?.itemSoldQty : 0}/{item?.itemQty} Nodes</span>
                                                                            <span className="text-weight-500">{new Intl.NumberFormat("ja-JP").format(percentSold)}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="node-bottom">
                                                                    <div className="text-center text-white weight-300">
                                                                        {item?.itemSoldQty < item?.itemQty ? (
                                                                            <button onClick={()=> handleActiveNode(item, key)} className="btn-basic">Purchase</button>
                                                                        ):(
                                                                            <button disabled className="btn-basic">SOLD OUT</button>
                                                                            
                                                                        )}
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }):(
                                                    <Col xs={24}>
                                                        <div className="text-center">
                                                            <Empty description={<span className="text-white">No data</span>} />
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                        )}

                                        {viewDisplay == "list" && (
                                            <>
                                                {helaNodeItem.length > 0 ? helaNodeItem.map((item, key) => {

                                                    let percentSold = Number(item?.itemSoldQty/item?.itemQty * 100);

                                                    return(
                                                        <div className={`grid-item ${item?.id == nodeActive?.id ? 'border-active':''} ${item?.itemSoldQty == item?.itemQty ? 'border-soldout':''}`} onClick={()=> handleActiveNode(item, key)}>
                                                            <Row gutter={20}>
                                                                <Col xs={4} sm={1} className="flex-btw">
                                                                    <img src="../images/node-item.svg" />
                                                                </Col>
                                                                <Col xs={20} sm={4} className="flex-btw">
                                                                    <div className="text-black-green text-weight-500 text-left">{item?.itemName}</div>
                                                                    <img src="../images/arb.svg" />
                                                                </Col>
                                                                <Col xs={12} sm={4} className="mt-10-sm">
                                                                    <div className="text-white weight-300 text-left">Price per Node</div>
                                                                    <div className="text-weight-500 text-left mt-10">{item?.itemPrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(item?.itemPrice) : 0} ETH</div>
                                                                </Col>
                                                                <Col xs={12} sm={4} className="mt-10-sm">
                                                                    <div className="text-white weight-300 text-left">Node for Sale</div>
                                                                    <div className="text-weight-500 text-left mt-10">{item?.itemQty > 0 ? new Intl.NumberFormat("ja-JP").format(item?.itemQty) : 0} <span className="weight-300">Nodes</span></div>
                                                                </Col>
                                                                <Col xs={12} sm={2} className="mt-10-sm">
                                                                    <div className="text-white weight-300 text-left">Pay in</div>
                                                                    <div className="text-weight-500 text-left mt-10">
                                                                        <div className="weight-600 flex-left">
                                                                            <img className="w-25" style={{marginRight: '5px'}} src="../images/eth.svg" alt="" />
                                                                            <span>ETH</span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={3} className="mt-10-sm">
                                                                    <div className="text-white weight-300 text-left">Node Sold</div>
                                                                    <div className="text-weight-500 text-left mt-10">{item?.itemSoldQty ? item?.itemSoldQty : 0}/{item?.itemQty} </div>
                                                                </Col>
                                                                <Col xs={24} sm={6} className="mt-10-sm">
                                                                    <div className="node-progress">
                                                                        <Progress showInfo={false} percent={percentSold} />
                                                                        <div className="flex-btw text-white">
                                                                            <span className="text-weight-500">{item?.itemSoldQty ? item?.itemSoldQty : 0}/{item?.itemQty} Nodes</span>
                                                                            <span className="text-weight-500">{new Intl.NumberFormat("ja-JP").format(percentSold)}%</span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </div>
                                                    )
                                                }):(
                                                    <Col xs={24}>
                                                        <div className="text-center">
                                                            <Empty description={<span className="text-white">No data</span>} />
                                                        </div>
                                                    </Col>
                                                )}
                                            </>
                                        )}

                                        
                                    </div>
                                </div>
                            </>
                        )}
                        
                    </div>
                </div>
            </div>
            <FooterHome />
            <Modal className="modal-mint" open={isModalSuccess} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal-content-mint">
                    <div className="title text-green">
                        Congratulation!
                    </div>
                    <div className="desc">
                        You successfully minted a Genesis NFT #1052 on Upswap.finance
                    </div>
                    <div className="img-modal">
                        <img style={{maxHeight: '400px'}} src={`../images/hela-nft.png`} />
                    </div>
                    <div className="name-modal">
                        <button className='btn-basic w-200' onClick={()=> window.location.replace(`/hela-nft`)}>View Item</button>
                    </div>
                    
                </div>
            </Modal>
            <Modal className="modal-mint" open={isModalFailed} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal-content-mint">
                    <div className="title text-red">
                        Failed!
                    </div>
                    <div className="desc">
                        Mint HeLa NFT failed, please try again.
                    </div>
                </div>
            </Modal>
            {loadingMint && (
                <>
                    <Loader />
                </>
            )}
        </>
    )
}

export default HelaNode
