import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import './components/common/asset/scss/style.scss';
import 'antd/dist/antd.css';
import Header from './components/common/header';
import MenuSideBar from './components/common/menuSideBar';
import Home from './components/home';
import LaunchPad from './components/launchpad';
import LaunchpadDetail from './components/launchpadDetail';
import Upcoming from './components/launchpad/upcoming';
import Opening from './components/launchpad/opening';
import Completed from './components/launchpad/completed';
import Joined from './components/launchpad/joined';
import Claimable from './components/launchpad/claimable';
import Stake from './components/stake';
import StakeFixible from './components/stake-fixible';
// import Airdrop from './components/airdrop';
// import DetailAirdrop from './components/airdrop/detailAirdrop';
import Ino from './components/ino';
import InoDetail from './components/ino/inoDetail'; 
import UpcomingIno from './components/ino/upcoming';
import EndedIno from './components/ino/ended'; 
import OpeningIno from './components/ino/opening'; 
import {useSelector, useDispatch} from 'react-redux'; 
import { ReactNotifications } from 'react-notifications-component'; 
import 'react-notifications-component/dist/theme.css'; 
// import UpcomingAirdrop from './components/airdrop/upcoming';
// import OpeningAirdrop from './components/airdrop/opening';
// import CompletedAirdrop from './components/airdrop/completed';
import GameHub from './components/gameHub';
import GameHubDetail from './components/gameHub/detailGamehub'; 
import Catalog from './components/gameHub/catalogGamehub';
import Terms from './components/terms';
import Privacy from './components/privacy';
import CookiesPage from './components/cookies';
import AirdropList from './components/airdrop/airdrop-list';
import AirdropCreate from './components/airdrop/create-airdrop';
import AirdropRewardDetail from './components/airdrop/airdrop-detail';
import PrivateCreate from './components/private-sale/private-create';
import CoomingSoonPage from './components/coming-soon';
import MultiSender from "./components/MultiSender"
import WalletGenerator from "./components/WalletGenerator"
import DetailGenerator from './components/WalletGenerator/DetailGenerator';
import DetailGeneratorPremium from './components/WalletGenerator/DetailGeneratorPremium';
import PrivateDetail from './components/private-sale/private-detail';
import PrivateList from './components/private-sale/private-list';
import Liquidity from "./components/Liquidity"
import CreateToken from './components/create-token';
import StakeToken from './components/launch-pools/stake-token';
import StakeMainToken from './components/launch-pools/stake-hup';
import MintNft from './components/mint-nft';
import MintFunction from './components/mint-nft/mint';
import MintDetails from './components/mint-nft/details';
import NftProfile from './components/mint-nft/nft-profile';
import OwnerDetails from './components/mint-nft/owner-detail';
import ListForSale from './components/mint-nft/owner-detail/list-for-sale';
import UserProfile from './components/profile';
import Helascripstion from './components/helascriptions';
import HelascriptionDetails from './components/helascriptions/details';
import HelascriptionToken from './components/helascriptions/helascription-token';
import TokenDetail from './components/helascriptions/helascription-token/token-detail';
import HelascripstionPerson from './components/helascriptions/helascription-person';
import ClaimHlup from './components/claim-hlup';
import IcoLaunchpad from './components/ico-launchpad';
import MyIcoProject from './components/ico-launchpad/my-project';
import IcoCreate from './components/ico-launchpad/launchpad-create/ico-create';
import IcoDetail from './components/ico-launchpad/ico-detail';
import GenesisNft from './components/marketplace';
import UploadImage from './components/upload-image';
import HelaNode from './components/hela-node';

function App() {
    const [toggleSidebar, setToggleSidebar] = useState();
    const [openMenuSP, setOpenMenuSP] = useState(false);
    const [isBgMain, setIsBgMain] = useState('is-bgMain');
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    const isToggleSidebar = (isToggleSidebar) => {
        setToggleSidebar(isToggleSidebar);
    };
    const openMenuSp = (value) => {
        setOpenMenuSP(value);
    };

    return (
        <Router>
            <div className={`p-app ${toggleSidebar===true ? 'is-toggle' : ''}`}>
                <Header openMenuSp={openMenuSp} />
                {/* <MenuSideBar openMenuSp={openMenuSP} isToggleSidebar={isToggleSidebar} /> */}
                <ReactNotifications />
                <>
                    <Routes>
                        <Route exact path="/" element={<Home/>} />
                      
                        <Route path="/launchpad" element={process.env.REACT_APP_MODE == "production" ? <CoomingSoonPage/> : <LaunchPad/>}> 
                            <Route path="/launchpad" element={<Navigate replace to="/launchpad/upcoming" />} /> 
                            <Route path="/launchpad/upcoming" element={<Upcoming />} />
                            <Route path="/launchpad/opening" element={<Opening />} />
                            <Route path="/launchpad/completed" element={<Completed />} />
                            <Route path="/launchpad/joined" element={<Joined />} />
                            <Route path="/launchpad/claimable" element={<Claimable />} />
                            
                        </Route>

                        <Route path="/launchpad/tba/:handle" element={<LaunchpadDetail/>} />
                        <Route path="/launchpad/upcoming/:handle" element={<LaunchpadDetail/>} />
                        <Route path="/launchpad/opening/:handle" element={<LaunchpadDetail/>} />
                        <Route path="/launchpad/completed/:handle" element={<LaunchpadDetail/>} />
                        <Route path="/launchpad/joined/:handle" element={<LaunchpadDetail/>} />
                        <Route path="/launchpad/claimable/:handle" element={<LaunchpadDetail/>} />
                        <Route path="/launchpad-detail/:handle" element={<LaunchpadDetail/>} />
                        
                        {/* <Route path="/ino" element={<Ino/>} >  */}
                        <Route path="/ino" element={process.env.REACT_APP_MODE == "production" ? <CoomingSoonPage/> : <Ino/>} > 
                            <Route path="/ino" element={<Navigate replace to="/ino/upcoming" />} /> 
                            <Route path="/ino/upcoming" element={<UpcomingIno/>} /> 
                            <Route path="/ino/opening" element={<OpeningIno/>} />
                            <Route path="/ino/ended" element={<EndedIno/>} />                
                            <Route path="/ino/upcoming/:handle" element={<InoDetail/>} /> 
                            <Route path="/ino/opening/:handle" element={<InoDetail/>} />
                            <Route path="/ino/ended/:handle" element={<InoDetail/>} />
                            <Route path="/ino/bought/:handle" element={<InoDetail/>} />
                        </Route> 
                        <Route path="/stake" element={<Stake/>} />
                        <Route path="/stake-fixible" element={<StakeFixible/>} />
                       
                        <Route path="/airdrop" element={<AirdropList/>} />
                        <Route path="/airdrop/create" element={<AirdropCreate/>} />
                        <Route path="/airdrop/detail/:airdropAddress/:handle" element={<AirdropRewardDetail />} />

                        <Route path="/private-sale/detail/:privateSaleId/:handle/:privateSaleAddress" element={process.env.REACT_APP_MODE == "production" ? <CoomingSoonPage/> : <PrivateDetail />} />
                        <Route path="/private-sale/create" element={process.env.REACT_APP_MODE == "production" ? <CoomingSoonPage/> : <PrivateCreate />} />
                        <Route path="/private-sale" element={process.env.REACT_APP_MODE == "production" ? <CoomingSoonPage/> : <PrivateList />} />

                        <Route path="/gamehub" element={<GameHub />} />
                        <Route path="/hela-nft" element={<GenesisNft />} />
                        <Route path="/gamehub/catalog/:handle" element={<Catalog />} />
                        <Route path="/gamehub/:handle" element={<GameHubDetail />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/cookies" element={<CookiesPage />} />
                        <Route path="/swap-bridge" element={<CoomingSoonPage />} />
                        <Route path="/limit-order" element={<CoomingSoonPage />} />
                        <Route path="/farms" element={<CoomingSoonPage />} />
                        <Route path="/stake-hlup" element={<StakeMainToken />} />
                        <Route path="/stake-token" element={<StakeToken />} />
                        <Route path="/wallet-generator" element={<WalletGenerator />} />
                        <Route path="/multi-sender" element={<MultiSender />} />
                        <Route path="/bot-volume" element={<CoomingSoonPage />} />
                        <Route path="/create-token" element={<CreateToken />} />
                        <Route path={`/wallet-generator/details-premium`} element={<DetailGeneratorPremium />} />
                        <Route path={`/wallet-generator/details`} element={<DetailGenerator />} />
                        <Route path="/add-liquidity" element={<Liquidity />} />
                        <Route path="/genesis-nft" element={process.env.REACT_APP_MODE == "production" ? <CoomingSoonPage/> : <MintNft />} />
                        <Route path="/genesis-nft/profile" element={<NftProfile />} />
                        <Route path="/mint-nft" element={<MintFunction />} />
                        <Route path="/nft-owner/list-for-sale/:id" element={<ListForSale />} />
                        <Route path="/nft-details/:id" element={<MintDetails />} />
                        <Route path="/nft-owner-details/:id" element={<OwnerDetails />} />
                        <Route path="/profile" element={<UserProfile />} />
                        <Route path="/helascription/:id" element={<HelascriptionDetails />} />
                        <Route path="/helascription" element={<Helascripstion />} />
                        <Route path="/helascription-token/:id" element={<TokenDetail />} />
                        <Route path="/helascription-tokens" element={<HelascriptionToken />} />
                        <Route path="/helascription-personal" element={<HelascripstionPerson />} />
                        <Route path="/helascription-market" element={<CoomingSoonPage />} />
                        <Route path="/claim-hlup" element={<ClaimHlup />} />
                        <Route path="/ico" element={<IcoLaunchpad />} />
                        <Route path="/ico/my-project" element={<MyIcoProject />} />
                        <Route path="/ico/create" element={<IcoCreate />} />
                        <Route path="/ico/detail/:id" element={<IcoDetail />} />
                        <Route path="/ico/detail/:id/:handle" element={<IcoDetail />} />

                        <Route path="/upload-image" element={<UploadImage />} />

                        <Route path="/hela-node" element={<HelaNode />} />
                        
                    </Routes>
                </>
                {/* <div className="c-backTop"><img src="../images/backTop.gif" alt="" /></div> */}
            </div>
        </Router>
    );
}

export default App;
