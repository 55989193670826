import { useCallback, useMemo } from 'react';
import abiBUSD from '../config/abi/abiBUSD.json';
import abiJoinPool from '../config/abi/abiJoinPool.json';
import abiJoinPoolV1 from '../config/abi/abiJoinPoolV1.json';
import deployAirdropAbi from '../config/abi/deployAirdropAbi.json';
import deployPrivateSaleAbi from '../config/abi/deployPrivateSaleAbi.json';
import deployLaunchpadAbi from '../config/abi/deployLaunchpadAbi.json';
import box from '../config/abi/box.json';
import market from '../config/abi/market.json';

import idoTokenClaimAbi from '../config/abi/idoTokenClaim.json';
import idoTokenClaimDailyAbi from '../config/abi/idoTokenClaimDaily.json';
import marketAbi from '../config/abi/marketAbi.json';
import marketGenesesAbi from '../config/abi/GenesesMarketplaceAbi.json';
import nodeSaleAbi from '../config/abi/nodeSaleAbi.json';
import abiBidNFT from '../config/abi/abiBidNFT.json';
import nftAbi from '../config/abi/nftAbi.json';

import { getContract } from '../utils';
import { useActiveWeb3React } from './index';;

// returns null on errors
export function useContract(address, ABI, withSignerIfPossible = true) {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useBUSDContract(address) {
  return useContract(address, abiBUSD);
}

export function useJoinPoolContract(address, oldVersion) {  
  let _abi;
  
  if(oldVersion === true) {
    _abi = abiJoinPoolV1;
  }
  else {
    _abi = abiJoinPool;
  }
  
  return useContract(address, _abi, true);
}

export function useJoinPoolContractV1(address) {  
  return useContract(address, abiJoinPoolV1, true);
}

export function useIdoTokenClaimContract(scAddr) {
    return useContract(scAddr, idoTokenClaimAbi);
}

export function useDeployLaunchpadContract(scAddr) {
	return useContract(scAddr, deployLaunchpadAbi);
}

export function useClaimDailyContract(scAddr) {
  return useContract(scAddr, idoTokenClaimDailyAbi);
}

export function useBoxContract(address) {
  return useContract(address, box, true)
}
export function useBoxMarketContract(address) {
  return useContract(address, market, true)
}

export function useMyBoxContract(address) {
  return useContract(address, box , true)
}
export function useDeployAirdropContract(scAddr) {
	return useContract(scAddr, deployAirdropAbi);
}

export function useDeployPrivateSaleContract(scAddr) {
	return useContract(scAddr, deployPrivateSaleAbi);
}

export function useMarketNFTContract(scAddr) {
    return useContract(scAddr, marketAbi);
}
export function useMarketGenesesNFTContract(scAddr) {
    return useContract(scAddr, marketGenesesAbi);
}
export function useSaleNodeContract(scAddr) {
    return useContract(scAddr, nodeSaleAbi);
}
export function useAutionNFTContract(scAddr) {
    return useContract(scAddr, abiBidNFT);
}
export function useNftContract(scAddr) {
    return useContract(scAddr, nftAbi);
}