
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio, Tabs, Tag } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { StopOutlined, CheckCircleOutlined } from '@ant-design/icons';

import web3 from 'web3';
import { ethers } from 'ethers';
import { getInformationByChain } from '../../config/network/multichainAddresses';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';
// import { postData, putData, getData } from "../../axios";
import abiHelaMintNFT from "../../config/abi/abiHelaMintNFT.json"
import ChainNotSupport from "../chain-not-support";

import NftMarketPlace from './nft-marketplace';
import FooterHome from '../common/footer/footerHome';
import ProfileMarketPlace from './profile-marketplace';
import { postData, putData } from '../mint-nft/axios';
import { GeneisNftApi } from '../../config/api/apiGeneisNFT';
import { _isHelaMinted } from './utils';
import { convertToWei } from '../../utils/convertNumber';
import networkChangeByNumber from '../../utils/networkChangeByNumber';
import CountdownReloadComponent from '../../utils/countDownReload';
import Wallet from '../common/wallet/wallet';


const GenesisNft = () => {
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loadingCheck, setLoadingCheck] = useState(false);
    const [loadingMint, setLoadingMint] = useState(false);
    const [isMinted, setIsMinted] = useState(0);
    const [totalMinted, setTotalMinted] = useState(0);
    const [canMint, setCanMint] = useState(null);
    const [count, setCount] = useState(1)
    const [mintItem, setMintItem] = useState(null);
    const [detailTab, setDetailTab] = useState("details");
    let mainKey = localStorage.getItem('mainKey');
    const [activeListTab, setActiveListTab] = useState(mainKey ? mainKey : "nft-marketplace");
    const [isModalSuccess, setIsModalSuccess] = useState(false);
    const [isModalFailed, setIsModalFailed] = useState(false);
    const [nftAddress, setNftAddress] = useState('');
    const [marketAddress, setMarketAddress] = useState('');

    const [startTimeBe, setStartTime] = useState('');
    const [endTimeBe, setEndTime] = useState('');

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let mintFee = 0.5;

    const getSCSettings = async (keyAddress, key)=>{
        try {
            let res = await GeneisNftApi.getSettingKey(keyAddress);
          
            if (res.status === 200 && res.data && res.data?.settingValue) {
                if(key == 'market'){
                    setMarketAddress(res.data?.settingValue);
                }
                if(key == 'nft'){
                    setNftAddress(res.data?.settingValue);
                }

                if(key == 'start_time'){
                    setStartTime(res.data?.settingValue);
                }
                if(key == 'end_time'){
                    setEndTime(res.data?.settingValue);
                }
                
            }else{
                setMarketAddress('');
                setNftAddress('');
                setStartTime('');
                setEndTime('');
            }
        } catch (error) {
            setMarketAddress('');
            setNftAddress('');
            setStartTime('');
            setEndTime('');
        }
    }
    useEffect(() => {
        getSCSettings('START_TIME_MINT_HELA_NFT', 'start_time');
        getSCSettings('END_TIME_MINT_HELA_NFT', 'end_time');
        getSCSettings('MARKET_HELA_NFT_CONTRACT', 'market');
        getSCSettings('HELA_NFT_CONTRACT', 'nft');
    }, [nftAddress, marketAddress, startTimeBe]);

    let contractMint = null;
    if(nftAddress){
        contractMint = new w3.eth.Contract(abiHelaMintNFT, nftAddress);
    }
    
    const checkIsMinted = async ()=>{
        await contractMint.methods.userNftCounts(account).call().then(res => {
            setIsMinted(Number(res));
        }).catch((error)=>{
            console.log('checkIsMinted-----', error);    
        })
    }
    const getTotalMinted = async ()=>{
        await contractMint.methods.totalSupply().call().then(res => {
            setTotalMinted(Number(res));
        }).catch((error)=>{
            console.log('getTotalMinted-----', error);    
        })
    }
    const checkUserWhitelist = async ()=>{
        setLoadingCheck(true);
        try {
            let res = await GeneisNftApi.checkUserHasWhitelist(account);
            
            if (res.status === 200 && res.data) {
                console.log('res.data', res.data);
                setCanMint(res.data);
                setLoadingCheck(false);
            } else {
                setCanMint(null);
                setLoadingCheck(false);
            }
        } catch (error) {
            console.log('Error: ', error);
            setCanMint(null);
            setLoadingCheck(false);
        }
    }

    useEffect(() => {
        if(account){
            checkUserWhitelist();
        }
    }, [account]);
    
    useEffect(() => {
        checkIsMinted();
        if(contractMint){
            getTotalMinted();       
        }
        

    }, [contractMint]);

    const itemsListTab = [
        {
            key: 'nft-marketplace',
            label: 'NFT Marketplace',
            children: <><NftMarketPlace nftAddress={nftAddress} marketAddress={marketAddress}/></>,
        },
        {
            key: 'my-nfts',
            label: 'My NFTs',
            children: <><ProfileMarketPlace nftAddress={nftAddress} marketAddress={marketAddress}/></>,
        }
    ];

    const onChangeTabList = (key) => {
        localStorage.setItem('mainKey', key);
        setActiveListTab(key);
        // window.location.reload();
    };
    const handleOk = () => {
        setIsModalSuccess(false);
        setIsModalFailed(false);
        setMintItem(null);
        window.location.reload();
    };
    const handleMyNFT = () => {
        localStorage.setItem('mainKey', 'my-nfts');
        window.location.replace(`/hela-nft`);
    };

    const handleCancel = () => {
        setIsModalSuccess(false);
        setIsModalFailed(false);
        setMintItem(null);
        window.location.reload();
    };

    

    const handleMintNft = async () => {
        try {
            if (account) {
                setLoadingMint(true);
                if(!contractMint){
                    Store.addNotification(addNotify('NFT address not found.', 'danger'));
                    setLoadingMint(false);
                    return;
                }
                const gasPrice = await w3.eth.getGasPrice();
                let data = {
                    chainId: chainId.toString(),
                    minterAddress: account
                };

                const dataRow = await postData(
                    `${process.env.REACT_APP_API_URL}/nft-geneses/generate-nft-id`, data,
                );
            
                if (dataRow && dataRow.nftId && dataRow?.sign) {
                    
                    await contractMint.methods.mintNFT(Number(dataRow.nftId), dataRow?.sign).send({
                        from: account,
                        gasPrice: w3.utils.toHex(String(gasPrice)),
                        value: mintFee > 0 ? convertToWei(Number(mintFee), 18) : 0,
                    }).then(async (res) => {

                        const interval = setInterval(function () {
                            w3.eth.getTransactionReceipt(
                                res?.transactionHash,
                                function (err, rec) {
                                    if (rec.status === true && res.transactionHash) {
                                        let dataApi = {
                                            id: dataRow.id,
                                            minterAddress: account,
                                            nftId: dataRow.nftId,
                                            tnxHash: res.transactionHash,
                                        }
                                        putData(`${process.env.REACT_APP_API_URL}/nft-geneses/${dataRow.id}/mint`, dataApi).then(resAfter => {
                                            // console.log('resAfter', resAfter);
                                            setMintItem(resAfter);
                                            if (resAfter.mintEnum === "FAILED") {
                                                setIsModalFailed(true);
                                                Store.addNotification(addNotify('Mint NFT Failed', 'danger'));
                                            } else {
                                                setIsModalSuccess(true);
                                                Store.addNotification(addNotify('Mint NFT Successfully', 'success'));
                                            }
                                            
                                            setLoadingMint(false);
                                        })
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        Store.addNotification(addNotify('Mint NFT Failed', 'danger'));

                                    }
                                }
                            );
                        }, 1000);
                    }).catch((error)=>{
                        Store.addNotification(addNotify(error.message, 'danger'));
                        setLoadingMint(false);
                    });
                }else{
                    Store.addNotification(addNotify(dataRow?.detail ? dataRow?.detail : 'Unknow Error.', 'danger'));
                    setLoadingMint(false);
                }
            }
        } catch (error) {
            console.error("error mint", error);
            setLoadingMint(false);
            // setLoadingMint(false);
            if (error) {
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                    setLoadingMint(false);
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                    setLoadingMint(false);
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                        setLoadingMint(false);
                    }
                }
            }
        }
    }

    let nowDate = new Date();
    let startTime = startTimeBe ? new Date(startTimeBe) : '';
    let endTime = endTimeBe ? new Date(endTimeBe) : '';

    return (
        <>
            <div className='market-page'>
                <div className="container">
                    <div className='mint-nft'>
                        <Row gutter={30}>
                            <Col xs={24} sm={8}>
                                <div className='nft-img border-radius-10'>
                                    <img src='../images/genis.gif' />
                                    {/* {canMint ? (
                                        <>
                                            <img src={canMint?.nftGenesisInfo?.imageUri} />
                                        </>
                                    ):(
                                        <>
                                            <img src='../images/genis.gif' />
                                        </>
                                    )} */}
                                    
                                </div>
                            </Col>
                            <Col xs={24} sm={16}>
                                {/* {loadingCheck ? (
                                    <Spin size='large'/>
                                ):(
                                    <></>
                                )} */}

                                <>
                                    {canMint ? (
                                        <>
                                            <div className='mint-title'>{canMint?.nftGenesisInfo?.name}</div>
                                            <p className='text-darkblue text-16'>{canMint?.nftGenesisInfo?.description}</p>
                                        </>
                                    ):(
                                        <>
                                            <div className='mint-title'>HeLa NFT</div>
                                            <p className='text-darkblue text-16'>Get a piece of our exclusive NFTs: OG, Early Bird, and Super HeLa Ranger. Enjoy unique perks like early access to new features, special events, and exclusive merchandise. Show your support and be recognized as a key member of our community!</p>
                                        </>
                                    )}
                                    

                                    <p className='mb-10 text-gray'>Creator: <strong>HeLa</strong></p>

                                    <div className='mint-info'>
                                        <Row gutter={30}>
                                            <Col xs={24} sm={10}>
                                                {startTime != '' && endTime != '' && startTime > nowDate && endTime > nowDate && (
                                                    <>
                                                        <p className='mb-10 text-darkgray'>Start In (UTC)</p>
                                                        <p className='text-18 text-weight-500 text-black mb-0'>
                                                            {startTimeBe ? CountdownReloadComponent(startTimeBe) : ''}
                                                        </p>
                                                    </>
                                                )}
                                                {startTime != '' && startTime < nowDate && endTime > nowDate && (
                                                    <>
                                                        <p className='mb-10 text-darkgray'>End In (UTC)</p>
                                                        <p className='text-18 text-weight-500 text-black mb-0'>
                                                            {CountdownReloadComponent(endTimeBe)}
                                                        </p>
                                                    </>
                                                )}
                                                {startTime != '' && startTime < nowDate && endTime < nowDate && (
                                                    <>
                                                        <p className='mb-10 text-darkgray'>Mint Time</p>
                                                        <p className='text-18 text-weight-500 text-black mb-0'>
                                                            ENDED
                                                        </p>
                                                    </>
                                                )}
                                            </Col>
                                            <Col xs={24} sm={7}>
                                                <p className='mb-10 text-darkgray'>Total Supply</p>
                                                <p className='text-18 text-weight-500 text-black mb-0'>530</p>
                                            </Col>
                                            <Col xs={24} sm={7}>
                                                <p className='mb-10 text-darkgray'>Total Minted</p>
                                                <p className='text-18 text-weight-500 text-black mb-0'>{totalMinted} NFTs</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    {chainId && chainId == 8668 ? (
                                        <div className='mint-btn-info mt-15'>
                                            {canMint ? (
                                                <>
                                                    {isMinted == 0 ? (
                                                        <>
                                                            {startTime != '' && startTime < nowDate && endTime > nowDate && (
                                                                <>
                                                                    <button className="btn-basic w-200" disabled={!account || loadingMint} onClick={handleMintNft}> {loadingMint ? 'Minting' : 'Mint'} {loadingMint && <Spin size="small" />}</button>
                                                                </>
                                                            )}

                                                            
                                                        </>
                                                    ):(
                                                        <>
                                                            <button className="btn-basic w-200" disabled> Minted</button>
                                                        </>
                                                    )}
                                                </>
                                            ):(
                                                <>
                                                    <button className={`btn-info w-fitcontent btn-border-danger`}><StopOutlined className="mr-5"/> You're not whitelisted</button>
                                                </>
                                            )}
                                        </div>
                                    ):(
                                        <>
                                            {chainId ? (
                                                <>
                                                    <button className="btn-basic w-fitcontent bg-danger mt-15" onClick={() => networkChangeByNumber(8668)}>
                                                        Incorrect network. Switch to HeLa Network
                                                    </button>
                                                </>
                                            ):(
                                                <>
                                                    <div className='mt-15'>
                                                        <Wallet />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                                
                            </Col>
                        </Row>
                    </div>
                    <div className='mint-list'>
                        <div className='mint-list-title'>
                            Explore <span>HeLa NFT</span>
                        </div>
                        <div className='mint-list-tabs'>
                            <Tabs className='tab-ido-general c-tabs' activeKey={activeListTab} items={itemsListTab} onChange={onChangeTabList}/>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
            <Modal className="modal-mint" open={isModalSuccess} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal-content-mint">
                    <div className="title text-green">
                        Congratulation!
                    </div>
                    <div className="desc">
                        You've successfully minted a HeLa NFT on Upswap.finance
                    </div>
                    <div className="img-modal">
                        <img style={{maxHeight: '400px'}} src={`${mintItem?.nftImage ? mintItem?.nftImage : '../images/hela-nft.png'}`} />
                    </div>
                    <div className="name-modal">
                        <button className='btn-basic w-200' onClick={handleMyNFT}>View Item</button>
                    </div>
                    
                </div>
            </Modal>
            <Modal className="modal-mint" open={isModalFailed} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal-content-mint">
                    <div className="title text-red">
                        Failed!
                    </div>
                    <div className="desc">
                        Mint HeLa NFT failed, please try again.
                    </div>
                </div>
            </Modal>
            
            {loadingMint && (
                <>
                    <Loader />
                </>
            )}
        </>
    )
}

export default GenesisNft
